import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { expand, Observable, of, scan, switchMap, takeWhile, tap } from 'rxjs';
import { User, UserPostResponse } from '../models/user.model';
import { UserPostParam } from '../models/user-post-param.model';
import { LoginResponse } from '../models/login-response.model';
import { TokenService } from './token.service';
import { ProjectService } from './project.service';
import { NavigationStateService } from './navigation-state.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  readonly #URL = `${environment.apiUrl}/users`;
  readonly #http = inject(HttpClient);

  usersSignal = signal<User[]>([]);
  getAllUsers(
    type: 'admins' | 'skippers' | 'users' = 'users',
  ): Observable<any[]> {
    this.usersSignal.set([]);
    const fetchPage = (page: number): Observable<any> => {
      return this.#http
        .get<any>(`${this.#URL}/list/${type}?page=${page}&perPage=1000`)
        .pipe(
          tap((response) =>
            this.usersSignal.update((prev) => [...prev, ...response.data.data]),
          ),
        );
    };

    return fetchPage(1).pipe(
      expand((response) => {
        if (response.data.next_page_url) {
          const nextPage = response.data.current_page + 1;
          return fetchPage(nextPage);
        }
        return of(null);
      }),
      takeWhile((response) => response !== null),
      scan((allUsers: any[], response) => {
        const newUser = response?.data.data ?? [];
        return allUsers.concat(newUser);
      }, []),
    );
  }

  tokenService = inject(TokenService);
  #projectService = inject(ProjectService);
  #navigationStateService = inject(NavigationStateService);
  logAs(id: number) {
    return this.#http.get<LoginResponse>(`${this.#URL}/logAs/${id}`).pipe(
      switchMap((user) => {
        this.tokenService.setDelegatedToken(user);

        return this.#projectService.getProjects().pipe(
          tap((projects) => {
            let projectId: number;
            if (user.user?.favorite_project) {
              projectId = user.user.favorite_project;
            } else {
              projectId = projects[0]?.clients[0]?.projects[0]?.id;
            }
            this.#navigationStateService.setProjectId(projectId!);
          }),
        );
      }),
    );
  }

  delete(id: number) {
    return this.#http.delete(`${this.#URL}/${id}`);
  }

  create(user: UserPostParam, type: 'admins' | 'skippers' | 'users' = 'users') {
    return this.#http.post<UserPostResponse>(`${this.#URL}/${type}`, user);
  }

  update(user: UserPostParam) {
    user.active = user.active ? 1 : 0;
    return this.#http.put<UserPostResponse>(`${this.#URL}/${user.id}`, user);
  }

  myData() {
    return this.#http.get<User>(`${environment.apiUrl}/me`);
  }

  importFile(body: any) {
    return this.#http.post(`${environment.apiUrl}/users/import`, body);
  }

  regeneratePassword(id: number) {
    return this.#http.get(`${this.#URL}/resetPassword/${id}`);
  }
}
